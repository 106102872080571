<template>
  <div class="page" style="text-align: left; padding: 45px">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>Draco Natural Products, Inc.</b>
            </p>
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>COMPOSITION BREAKDOWN STATEMENT</u></b>
            </p>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.formModel.methodCode">
            <td style="width: 25%"><b>Method Code</b></td>
            <td style="width: 75%">
              {{ printModel.formModel.methodCode }}
            </td>
          </tr>
          <tr v-show="printModel.formModel.methodName">
            <td style="width: 25%"><b>Method Name</b></td>
            <td style="width: 75%">
              {{ printModel.formModel.methodName }}
            </td>
          </tr>
          <tr v-show="printModel.formModel.methodUsage">
            <td style="width: 25%"><b>Usage</b></td>
            <td style="width: 75%">
              {{ printModel.formModel.methodUsage }}
            </td>
          </tr>
          <tr v-show="printModel.formModel.reference">
            <td style="width: 25%"><b>Reference</b></td>
            <td style="width: 75%">
              {{ printModel.formModel.reference }}
            </td>
          </tr>
          <tr v-show="printModel.formModel.sop">
            <td style="width: 25%"><b>SOP</b></td>
            <td style="width: 75%">
              <div v-html="printModel.formModel.sop.replace(/\n|\r\n/g, '<br>')"></div>
            </td>
          </tr>
          <tr v-show="printModel.formModel.specialRequirement">
            <td style="width: 25%"><b>Special Requirement</b></td>
            <td style="width: 75%">
              {{ printModel.formModel.specialRequirement }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import base from "@/common/base.js";
export default {
  name: "TestMethod",
  mixins: [base],
  props: ["printModel"],
  data() {
    return {};
  },
  computed: {
  },
  methods: {
  },
  created() {
  },
  mounted() {
    console.log("====printModel====");
    console.log(this.printModel);
  },
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>