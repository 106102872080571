var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",staticStyle:{"text-align":"left","padding":"45px"}},[_c('div',{staticClass:"row"},[_c('div',{staticStyle:{"width":"99%"}},[_vm._m(0),_c('table',{staticStyle:{"width":"99%"}},[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.printModel.formModel.methodCode),expression:"printModel.formModel.methodCode"}]},[_vm._m(1),_c('td',{staticStyle:{"width":"75%"}},[_vm._v(" "+_vm._s(_vm.printModel.formModel.methodCode)+" ")])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.printModel.formModel.methodName),expression:"printModel.formModel.methodName"}]},[_vm._m(2),_c('td',{staticStyle:{"width":"75%"}},[_vm._v(" "+_vm._s(_vm.printModel.formModel.methodName)+" ")])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.printModel.formModel.methodUsage),expression:"printModel.formModel.methodUsage"}]},[_vm._m(3),_c('td',{staticStyle:{"width":"75%"}},[_vm._v(" "+_vm._s(_vm.printModel.formModel.methodUsage)+" ")])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.printModel.formModel.reference),expression:"printModel.formModel.reference"}]},[_vm._m(4),_c('td',{staticStyle:{"width":"75%"}},[_vm._v(" "+_vm._s(_vm.printModel.formModel.reference)+" ")])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.printModel.formModel.sop),expression:"printModel.formModel.sop"}]},[_vm._m(5),_c('td',{staticStyle:{"width":"75%"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.printModel.formModel.sop.replace(/\n|\r\n/g, '<br>'))}})])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.printModel.formModel.specialRequirement),expression:"printModel.formModel.specialRequirement"}]},[_vm._m(6),_c('td',{staticStyle:{"width":"75%"}},[_vm._v(" "+_vm._s(_vm.printModel.formModel.specialRequirement)+" ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header",staticStyle:{"width":"100%","height":"100px"},attrs:{"id":"header"}},[_c('div',{staticStyle:{"width":"11%","float":"left"}},[_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":require("../../assets/print_logo.jpg")}})]),_c('div',{staticStyle:{"width":"89%","float":"right"}},[_c('p',{staticStyle:{"font-size":"28px","font-family":"Times New Roman","text-align":"center","vertical-align":"text-top"}},[_c('b',[_vm._v("Draco Natural Products, Inc.")])]),_c('p',{staticStyle:{"font-size":"28px","font-family":"Times New Roman","text-align":"center","vertical-align":"text-top"}},[_c('b',[_c('u',[_vm._v("COMPOSITION BREAKDOWN STATEMENT")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"25%"}},[_c('b',[_vm._v("Method Code")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"25%"}},[_c('b',[_vm._v("Method Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"25%"}},[_c('b',[_vm._v("Usage")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"25%"}},[_c('b',[_vm._v("Reference")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"25%"}},[_c('b',[_vm._v("SOP")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"25%"}},[_c('b',[_vm._v("Special Requirement")])])
}]

export { render, staticRenderFns }